//DEV
const dev_baseURL = 'https://iqos.cgmdev.org/api/';

//STAGING
const stag_baseURL = 'https://services-stag-vs.iqositalia.it/api/'; //base url for shop stream
const stag_baseBP_URL = 'https://api-stag-bp.iqositalia.it/api/'; //base url from booking platform : BP

//PROD
const prod_baseURL = 'https://services-vs.iqositalia.it/api/';
const prod_baseBP_URL = 'https://api-bp.iqositalia.it/api/';

//SENDBIRD
const sbBaseUrl = 'https://api-1A2F3370-1790-4A3D-A152-6ED0E6CA528C.sendbird.com/v3';

export const PRODUCTION = false;

const env = () => {
	if (PRODUCTION) {
		return { baseURL: prod_baseURL, bpBaseURL: prod_baseBP_URL, sbBaseURL: sbBaseUrl };
	}
	return { baseURL: stag_baseURL, bpBaseURL: stag_baseBP_URL, sbBaseURL: sbBaseUrl };
};

export default env;
